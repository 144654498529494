<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="姓名" prop="nickname" :rules="rules.required">
					<el-input v-model="form.nickname" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="name" :rules="[rules.required, rules.phone]">
					<div class="flex">
					<el-input v-model="form.name" placeholder="手机号码将作为登录帐号使用"></el-input>
					<div class="text-gray margin-left-sm" style="width: 350px;"><i class="el-icon-info"></i> 手机号码将作为登录帐号使用</div>
					</div>
				</el-form-item>
				<el-form-item label="密码" prop="pwd" :rules="passwordRules">
					<el-input type="password" show-password v-model="form.pwd" :placeholder="form.id ? '不填写则不进行密码修改': '请输入密码'"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-switch v-model="form.status" :active-text="form.status?'启用':'禁用'" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="角色" prop="role" :rules="rules.notEmptyArray">
					<el-checkbox-group v-model="form.role">
						<el-checkbox :label="item.role_id" v-for="(item, index) in roleOptions" :key="index">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">{{saveBtn}}</el-button>
					<el-button @click="$router.go(-1)">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				checkList: [],
				rules,
				title: this.$route.params.id ? "编辑用户" : "新增用户",
				saveBtn: this.$route.params.id ? "确认保存" : "确认创建",
				roleOptions: [],
				showPasswordInput: true,
				form: {
					id: this.$route.params.id,
					name: "",
					nickname: "",
					pwd: "",
					status: 1,
					role: []
				}
			};
		},
		computed: {
			// 密码校验规则
			passwordRules() {
				let rules = [this.rules.notSpecial]
				if (!this.form.id) {
					rules.push(this.rules.required)
				}
				return rules
			}
		},
		mounted() {
			// 获取角色列表
			this.$api.system.getRoles().then(res => {
				this.roleOptions = res.data;
			});
			if (this.form.id) {
				this.$api.user.getUser({
					id: this.form.id
				}).then(res => {
					this.form = res.data
					this.form.role = this.form.role ? this.form.role : []
				});
			}
		},
		methods: {
			change() {
				// this.form.role = this.checkList;
			},
			onSubmit() {
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.user.save(this.form).then(res => {
								loading.close();
								console.log(res)
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
